import {Route} from 'react-router-dom';
import React from 'react';
import HomeComponent from '../views/home/home-component';


export const UserRoute = ({component: Component, ...rest}) => {
  return (
    <Route
      {...rest}
      render={props => (
        <HomeComponent><Component {...props} /></HomeComponent>
      )
      }
    />
  );
};
