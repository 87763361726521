import {Route, Redirect} from 'react-router-dom';
import React, {useContext} from 'react';
import {Sidebar} from '../components/sidebar/sidebar';
import Authorized from './authorized';
import {StateContext} from '../store';

export const ProtectedRoute = ({component: Component, ...rest}) => {
  const {user} = useContext(StateContext);
  return (
    <Route
      {...rest}
      render={props => {
        return user.authenticated ? (
            <Sidebar><Authorized Component={Component} {...rest}/></Sidebar>
          )
          :
          (
            <Redirect exact to={{pathname: '/login', state: {form: props.location}}}/>
          );
      }

      }
    />
  );
};
