import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import {Grid} from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import * as Yup from 'yup';
import {Notify} from '../../../shared/notification';
import {reservationApi} from '../../../services/userServices';

const validationSchema = () => Yup.object().shape({
  customer_name: Yup.string().required('Field is required'),
  phone: Yup.string().required('Field is required'),
  date: Yup.string().required('Field is required'),
  from: Yup.string().required('Field is required'),
  to: Yup.string().required('Field is required'),
});

const FacilityReservation = ({id, open, handleAction}) => {

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const initialValues = {
    customer_name: '',
    phone: '',
    date: '',
    from: '',
    to: ''
  };

  const onSubmit = (values, {setSubmitting}) => {
    setSubmitting(false);
    reservationApi(id, {reservation: {...values}}, null, 'post').then(response => {
      Notify('Facility Booked successfully', 'success');
      handleAction(false);
    }).catch(err => {
      setSubmitting(false);
      Notify(err, 'error');
    });
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => handleAction(false)}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle id="alert-dialog-slide-title">{'Book Your Ground'}</DialogTitle>
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnBlur={true}
        validationSchema={validationSchema}
      >
        {({isSubmitting, handleChange, touched, errors, dirty, values, handleBlur, ...formik}) => (
          <Form>
            <div>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item lg={12} xs={12}>
                    <Field component={TextField} label="Name" name="customer_name" variant="outlined" margin="dense"
                           fullWidth/>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Field component={TextField} label="Phone" name="phone" variant="outlined" margin="dense"
                           fullWidth/>
                  </Grid>
                  <Grid item lg={12} xs={12}>
                    <Field component={TextField} type="date" name="date" variant="outlined" margin="dense" label="Date"
                           InputLabelProps={{shrink: true}} fullWidth/>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Field component={TextField} type="time" label="From" name="from" variant="outlined" margin="dense"
                           InputLabelProps={{shrink: true}}  fullWidth/>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <Field component={TextField} type="time" label="To" name="to" variant="outlined" margin="dense"
                           fullWidth InputLabelProps={{shrink: true}}/>
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button style={{marginRight: 15}}
                        variant="contained"
                        color="primary"
                        type="submit"
                        disabled={isSubmitting}
                >
                  Book
                </Button>
                <Button onClick={() => handleAction(false)} color="default" variant={'outlined'} autoFocus>
                  Cancel
                </Button>
              </DialogActions>
            </div>
          </Form>
        )}
      </Formik>
    </Dialog>
  );
};

export default FacilityReservation;
