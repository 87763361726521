import React, {useEffect, useState} from 'react';
import {FormControl, Grid, InputLabel, Paper, Select, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import './add-facility.scss';
import Button from '@material-ui/core/Button';
import {Dzongkhag} from '../../../shared/dzongkhags';
import {categoryList, facilityAction, facilityAPi} from '../../../services/verdor.service';
import {serialize as o2f} from 'object-to-formdata';
import {Notify, setProps} from '../../../shared/notification';
import {useHistory, useLocation, useParams} from 'react-router-dom';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import * as Yup from 'yup';
import {DropzoneArea} from 'material-ui-dropzone';
import {useSnackbar} from 'notistack';

const validationSchema = () => Yup.object().shape({
  description: Yup.string().required('Field is required'),
  facility_category_id: Yup.string().required('Field is required'),
  name: Yup.string().required('Field is required'),
  location: Yup.string().required('Field is required'),
  dzongkhag: Yup.string().required('Field is required'),
  photos_attributes: Yup.mixed().required('Field is required'),
});

export const AddFacility = (props) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const search = new URLSearchParams(location.search);
  const id = params?.id;
  const edit = search.get('edit');
  const [show, setShow] = useState(false);
  const [facility, setFacility] = useState({});
  const [categories, setCategories] = useState([]);
  const snackbar = useSnackbar();
  setProps(snackbar);

  const initialValues = {
    facility_category_id: '',
    name: '',
    location: '',
    dzongkhag: '',
    description: ''
  };

  const handleDzongkhag = (e, value, formikBag) => {
    formikBag.setFieldValue('dzongkhag', value.name, true);
  };

  useEffect(() => {
    setProps(props);
    fetchFacility();
    categoryFetch();
  }, []);

  const fetchFacility = async () => {
    await facilityAction(id, 'get').then(res => {
      setFacility(res.facility);
      setShow(true);
    }).catch(err => {
      setShow(true);
    });
  };

  const categoryFetch = async () => {
    await categoryList().then(response => {
      response.facility_categories.unshift({id: '', category: ' '});
      setCategories([...response.facility_categories]);
    }).catch(err => {
      Notify(err, 'error');
    });
  };


  const onUpdate = (values, {setSubmitting}) => {
    setSubmitting(false);
    const formData = o2f({...values}, {indices: false}, null, `facility`);
    facilityAction(id, 'put', null, formData).then(res => {
      history.push(`/list-facility`);
      setShow(true);
      Notify('Facility Updated Successfully', 'success');
    }).catch(error => {
      setShow(true);
      setSubmitting(false);
      Notify(error, 'error');
    });
  };

  const onSubmit = (values, {setSubmitting}) => {
    const formData = o2f({...values}, {indices: false}, null, `facility`);
    facilityAPi('post', null, formData).then(response => {
      Notify('Facility created successfully', 'success');
      history.push('/list-facility');
    }).catch(err => {
      setSubmitting(false);
      Notify(err, 'error');
    });
  };

  const handleUpload = (files, values, formikBag) => {
    files?.map((file, index) => {
      formikBag.setFieldValue(`photos_attributes[${index}].image`, file, true);
    });
  };

  return (
    <div align="center">
      {show &&
      <Paper className="create-paper">
        <Typography variant="h5" className="mb-2">Add New Facility</Typography>
        <Formik
          initialValues={edit ? facility : initialValues}
          onSubmit={edit ? onUpdate : onSubmit}
          validateOnBlur={true}
          validationSchema={validationSchema}
        >
          {({isSubmitting, handleChange, touched, errors, dirty, values, handleBlur, ...formik}) => (
            <Form>
              <Grid container>
                <Grid item lg={12} xs={12}>
                  <FormControl variant="outlined" fullWidth className="mt-2">
                    <InputLabel htmlFor="outlined-age-native-simple">Facility Type</InputLabel>
                    <Select
                      native
                      onChange={handleChange}
                      value={values?.facility_category_id}
                      label="Facility Type"
                      inputProps={{
                        name: 'facility_category_id',
                        id: 'outlined-age-native-simple',
                      }}
                    >
                      {
                        categories?.map(val => (
                          <option value={val?.id} key={val?.id} className="text-capitalize">{val?.name}</option>
                        ))}
                    </Select>
                  </FormControl>
                  <Field component={TextField} name="name" className="mt-2"
                         variant="outlined" label="Facility name" fullWidth/>
                  <Field component={TextField} name="location" className="mt-2"
                         variant="outlined" label="Location" fullWidth/>
                  <Field component={Autocomplete}
                         id="combo-box-demo"
                         options={Dzongkhag}
                         getOptionLabel={(option) => option.name}
                         onChange={(e, option) => handleDzongkhag(e, option, formik)}
                         value={Dzongkhag.find(val => val.name === values?.dzongkhag)}
                         style={{width: '100%', marginTop: 20, marginBottom: 20}}
                         renderInput={(params) =>
                           <Field component={TextField}
                                  {...params}
                                  name="dzongkhag"
                                  label="Select Dzongkhag"
                                  variant="outlined"
                                  InputProps={{...params.InputProps, type: 'dzongkhag'}}/>}
                  />
                  <Field component={TextField} multiline rows={3} name="description" className="mt-2 mb-2"
                         variant="outlined" label="Description" fullWidth/>
                  <div style={{marginTop: 20}}>
                    <DropzoneArea maxFileSize={5000000} filesLimit={5} showPreviews={false}
                                  onChange={(file) => handleUpload(file, values?.photos_attribute, formik)}
                                  showPreviewsInDropzone={true} dropzoneClass="dropZone"
                                  dropzoneText="Upload facility photo"
                                  initialFiles={values?.photos_attributes?.map(img => img?.large)}
                                  acceptedFiles={['image/*']}
                                  name="photos_attributes"
                                  dropzoneParagraphClass="dropZoneDes" showAlerts={true}
                    />
                  </div>
                  <Button variant="contained" color="primary"
                          style={{marginTop: 20, height: 50, width: '100%'}}
                          type="submit"
                          disabled={isSubmitting}>{edit ? 'Update Facility' : 'Create Facility'}</Button>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Paper>
      }
    </div>
  );
};