import React, {useContext, useState} from 'react';
import clsx from 'clsx';
import {Link, useHistory} from 'react-router-dom';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import HomeIcon from '@material-ui/icons/Home';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {Typography} from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import Search from '../../shared/search/search';
import './header.scss';
import {DispatchContext, IS_LOADING, NOT_LOADING, StateContext} from '../../store';
import {logout} from '../../services/auth';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import SportsEsportsOutlinedIcon from '@material-ui/icons/SportsEsportsOutlined';
import AddAlertOutlinedIcon from '@material-ui/icons/AddAlertOutlined';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  hide: {
    display: 'none',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  drawerHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
  },
  wrapper: {
    marginTop: 10,
  },
}));

export const Header = () => {
  const dispatch = useContext(DispatchContext);
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const {user} = useContext(StateContext);
  const history = useHistory();

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const signOut = async () => {
    dispatch({type: IS_LOADING});
    await logout(dispatch);
    dispatch({type: NOT_LOADING});
    history.push('/login');
  };

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar position="fixed" style={{boxShadow: 'none'}} className={clsx(classes.appBar, 'header', {})}>
        <Toolbar className={classes.toolbar}>
          <a href={'/'} style={{textDecoration: 'none'}}>
            <Typography variant="h3" className="logo-text">PLAYGROUND</Typography>
          </a>
          <div style={{flexGrow: 1}}/>
          <div>
            <ul className="mt-2 nav-container">
              <nav style={{backgroundColor: 'transparent'}}>
                <Link to="/" className="head-link hand-cursor">HOME</Link>
              </nav>
              <nav style={{backgroundColor: 'transparent'}}>
                <a href="#contact" className={'head-link hand-cursor'}>SPORTS VENUE FACILITATORS</a>
              </nav>
              <nav style={{backgroundColor: 'transparent'}}>
                <a href="#contact" className={'head-link hand-cursor'}>RESERVE A GROUND</a>
              </nav>
              {!user.authenticated &&
              <nav style={{backgroundColor: 'transparent'}}>
                <Link to="/register" className={'head-link hand-cursor'}>REGISTER AS A VENDOR</Link>
              </nav>
              }
              {user.authenticated ?
                <>
                  <nav style={{backgroundColor: 'transparent'}}>
                    <Link to="/profile" className="head-link hand-cursor">DASHBOARD</Link>
                  </nav>
                  <nav style={{backgroundColor: 'transparent'}}>
                    <Link onClick={signOut} className="head-link hand-cursor">LOGOUT</Link>
                  </nav>
                </>
                :
                <nav style={{backgroundColor: 'transparent'}}>
                  <Link to="/login" className="head-link hand-cursor">LOGIN</Link>
                </nav>
              }
            </ul>
          </div>
          <IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerOpen} edge="start"
                      className={classes.menuButton}>
            <MenuIcon/>
          </IconButton>
          <Drawer className={classes.drawer} variant={'temporary'} anchor="right" open={open}
                  onClose={handleDrawerClose}
          >
            <div className={classes.drawer} onClick={handleDrawerClose}>
              <Link to={'/'} style={{textDecoration: 'none'}}>
                <List>
                  {['HOME'].map((text) => (
                    <ListItem button key={text}>
                      <ListItemText secondary={text}/>
                      <HomeIcon/>
                    </ListItem>
                  ))}
                </List>
              </Link>
              <Link to={'/'} style={{textDecoration: 'none'}}>
                <List>
                  {['SPORTS VENUE FACILITATOR'].map((text) => (
                    <ListItem button key={text}>
                      <ListItemText secondary={text}/>
                      <SportsEsportsOutlinedIcon/>
                    </ListItem>
                  ))}
                </List>
              </Link>
              <Link to={'/'} style={{textDecoration: 'none'}}>
                <List>
                  {['RESERVE A GROUND'].map((text) => (
                    <ListItem button key={text}>
                      <ListItemText secondary={text}/>
                      <AddAlertOutlinedIcon/>
                    </ListItem>
                  ))}
                </List>
              </Link>
              <Link to={'/register'} style={{textDecoration: 'none'}}>
                <List>
                  {['REGISTER AS A VENDOR'].map((text) => (
                    <ListItem button key={text}>
                      <ListItemText secondary={text}/>
                      <StorefrontOutlinedIcon/>
                    </ListItem>
                  ))}
                </List>
              </Link>
              <Link to={'/login'} style={{textDecoration: 'none'}}>
                <List>
                  {['LOGIN'].map((text) => (
                    <ListItem button key={text}>
                      <ListItemText secondary={text}/>
                      <ExitToAppIcon/>
                    </ListItem>
                  ))}
                </List>
              </Link>
            </div>
          </Drawer>
        </Toolbar>
        <Hidden smUp>
          <div className="p-1" style={{height: 65, width: '100%', backgroundColor: '#3ca7f7'}}>
            <div>
              <Search placeholder="Search Sports"/>
            </div>
          </div>
        </Hidden>
        <Hidden smDown>
          <div style={{height: 40, width: '100%', backgroundColor: '#3ca7f7'}}>
            <Typography style={{textAlign: 'center', marginTop: 7, fontSize: 18}}>
              Sports is back <span style={{color: 'black'}}>for Everyone</span>
            </Typography>
          </div>
        </Hidden>
      </AppBar>
    </div>
  );
};