import React, {useEffect, useState} from 'react';
import './list-sport-facility.scss';
import {Card, Grid} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import {Notify, setProps} from '../../shared/notification';
import {facilityAPi} from '../../services/verdor.service';
import {truncate} from '../../shared/truncate';
import {useSnackbar} from 'notistack';

export const SportFacility = ({querySearch}) => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [facilities, setFacilities] = useState({});

  useEffect(() => {
    setProps(snackbar);
    fetchServices();
  }, [querySearch]);

  const fetchServices = () => {
    facilityAPi('get', {
      per_page: 8,
      q:querySearch
    }).then(response => {
      setFacilities(response.facilities);
      setOpen(true);
    }).catch(err => {
      setOpen(true);
      Notify(err, 'error');
    });
  };

  const viewDetail = (id) => {
    history.push(`/facility/${id}`);
  };

  const viewAll = () => {
    history.push('/view-all-facility');
  };

  return (
    <div className="facility-section">
      <h2 className="facilities">Sports Facilities</h2>
      {open &&
      <Grid container spacing={2}>
        {facilities.length > 0 ? facilities.map((list, index) => (
            <Grid item lg={3} xs={12}>
              <Card>
                <div>
                  <img src={list?.photos_attributes?.[0]?.medium} alt="" className="card-image"/>
                </div>
                <CardContent
                  style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 18}}>
                  <Typography variant="h6" className="vendor">
                    {truncate(list?.name)}
                  </Typography>
                  <Button variant="contained" className="view-button text-capitalize"
                          onClick={() => viewDetail(list?.id)}>View
                    Detail</Button>
                </CardContent>
              </Card>
            </Grid>
          )) :
          (<Typography variant={'h6'} align={'center'} component="div">
              No data to display
            </Typography>
          )
        }
      </Grid>
      }
      <Grid align="right">
        <Button variant="contained" className="view-all text-capitalize" onClick={() => viewAll()}>View All</Button>
      </Grid>
    </div>
  );
};
