import React, {useContext, useEffect} from 'react';
import {Grid, Typography} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import './style.scss';
import Button from '@material-ui/core/Button';
import {DispatchContext, StateContext} from '../../store';
import {useHistory} from 'react-router-dom';
import {login} from '../../services/auth';
import {Formik} from 'formik';
import {Notify, setProps} from '../../shared/notification';
import {useSnackbar} from 'notistack';

export const SignIn = (props) => {
  const history = useHistory();
  const dispatch = useContext(DispatchContext);
  const {user} = useContext(StateContext);
  const snackbar = useSnackbar();

  useEffect(() => {
    setProps(snackbar);
    if (user.authenticated) {
      if (user?.role_ids?.[0]?.name === 'Admin') {
        history.push('/profile');
      } else {
        history.push('/');
      }
    }
  }, []);

  const handleLogin = (values, {setSubmitting}) => {
    login({user: {...values, login: values.email}}, dispatch).then(response => {
      if (response.user.role_ids[0].name === 'Admin') {
        history.push('/profile');
      } else {
        history.push('/');
      }
      Notify('Login Successfully', 'success');
    }).catch(err => {
      setSubmitting(false);
      Notify(err, 'error');
    });
  };

  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = 'Field is Required';
    }
    if (!values.password) {
      errors.password = 'Field is Required';
    }
    return errors;
  };

  const registerForm = () => {
    history.push('/register');
  };

  return (
    <div className="auth-page">
      <Grid container>
        <Grid item lg={12} xs={12} align="center">
          <Paper className="login-paper">
            <div>
              <Typography className="login-text">
                Login
              </Typography>
            </div>
            <Formik
              initialValues={{email: '', password: ''}}
              onSubmit={handleLogin}
              validateOnBlur={true}
              validate={(values => validate(values))}
            >
              {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  isSubmitting,
                  values,
                  dirty
                }) => (
                <form noValidate onSubmit={handleSubmit}>
                  <Grid container spacing={2}>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        variant="outlined"
                        name="email"
                        label="Email"
                        fullWidth
                        autoComplete="email"
                        helperText={touched['email'] && errors['email']}
                        error={!!errors['email'] && touched['email']}
                        onChange={handleChange}
                        onBlur={handleBlur}/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <TextField
                        variant="outlined"
                        label="Password"
                        type="password"
                        name="password"
                        fullWidth
                        autoComplete="password"
                        helperText={touched['email'] && errors['email']}
                        error={!!errors['email'] && touched['email']}
                        onChange={handleChange}
                        onBlur={handleBlur}/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Button
                        variant="contained"
                        className="btn-login"
                        type="submit"
                        disabled={isSubmitting || !dirty}>Login</Button>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Button variant="contained" className="create-account mt-1" onClick={registerForm}>Create an
                        Account</Button>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className="mt-2 hand-cursor" onClick={() => history.push('/')}>Back to
                        home</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className="mt-1 gray-color">Copyright @ Dragon Coders Pvt. Ltd</Typography>
                    </Grid>
                  </Grid>
                </form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};