import React, {useContext, useState} from 'react';
import {Grid} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import Typography from '@material-ui/core/Typography';
import {userApi} from '../../services/userServices';
import {serialize as o2f} from 'object-to-formdata';
import {DispatchContext} from '../../store';
import {Notify} from '../notification';

const useStyles = makeStyles(theme => ({
  icon: {
    color: 'blue',
    marginRight: 10
  },
  iconFont: {
    color: 'blue',
    marginRight: 10,
    fontSize: 60,
  },
  button: {
    border: '1px dashed black',
    textAlign: 'center',
    borderRadius: 4,
    margin: 'auto',
    padding: 8,
    cursor: 'pointer',
  },
  preview: {
    width: '100%',
    height: 75,
  },
  border: {
    border: '1px dashed black',
    padding: 20,
  },
  closeIcon: {
    position: 'absolute',
    cursor: 'pointer',
    color: 'red',
  },
  text: {
    fontWeight: 'bold',
    color: '#000000',
    fontSize: '0.5rem'
  }
}));

export const SharedImageUpload = ({img}) => {
  const classes = useStyles();
  const [image, setImage] = useState(img);
  const dispatch = useContext(DispatchContext);

  const handleLogoUpload = async ({target: {files: [file]}}) => {
    await userApi(o2f({profile_attributes: {logo_attributes: {image: file}}}, null, null, 'user'), null, 'put', dispatch).then(res => {
      setImage(res?.user?.profile_attributes?.logo_attributes?.small);
      // window.location.reload(false);
      Notify('Logo uploaded successfully', 'success');
    });
  };

  return (
    <React.Fragment>
      <div className={classes.border}>
        <Grid container spacing={4}>
          <Grid item xs={3} lg={3}>
            {image &&
            <img className={classes.preview} src={image} alt=""/>
            }
            {/*<CloseIcon className={classes.closeIcon}/>*/}
          </Grid>
          <Grid item lg={12} xs={12} align="center">
            <label className="hand-cursor">
              <div>
                <CloudUploadIcon className={classes.iconFont}/>
              </div>
              <Typography variant="h6">Upload Logo</Typography>
              <input accept="image/*" name="image" id="icon-button-file" hidden
                     type="file" onChange={handleLogoUpload}/>
            </label>
          </Grid>
        </Grid>
      </div>
    </React.Fragment>
  );
};
