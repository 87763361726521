import React, {useContext, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import './profile.scss';
import {Avatar, Divider, Tooltip, Typography} from '@material-ui/core';
import {DispatchContext, StateContext} from '../../store';
import {userApi} from '../../services/userServices';
import {serialize as o2f} from 'object-to-formdata';
import AddAPhotoIcon from '@material-ui/icons/AddAPhoto';
import Grid from '@material-ui/core/Grid';
import EditIcon from '@material-ui/icons/Edit';
import UserEditProfile from './profileEdit';
import {SharedImageUpload} from '../../shared/image-upload/image-upload';

export const Profile = (props) => {
  const dispatch = useContext(DispatchContext);
  const {user} = useContext(StateContext);
  const [open, setOpen] = useState(false);

  const handleProfileUpload = async ({target: {files: [file]}}) => {
    await userApi(o2f({profile_attributes: {profile_photo_attributes: {image: file}}}, null, null, 'user'), null, 'put', dispatch).then(res => {
      localStorage.setItem('user', JSON.stringify(res['user']));
      dispatch({type: 'LOGIN', payload: res.user});
    });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div align="center">
      <Paper className="p-2 paper">
        <div align="center">
          <Grid item lg={3} md={3} xs={4}>
            <input accept="image/*" name="image" id="icon-button-file" hidden
                   type="file" onChange={handleProfileUpload}/>
            <label htmlFor="icon-button-file">
              <img
                src={user?.profile_attributes?.profile_photo_attributes?.original || require('../../assets/images/background-banner.png').default}
                alt="pic" className="mb-2"
                style={{width: 70, height: 70, borderRadius: 50}}/>
              <Tooltip title="Change photo">
                <Avatar className="profile-edit hand-cursor">
                  <AddAPhotoIcon fontSize="small" htmlFor="icon-button-file"/>
                </Avatar>
              </Tooltip>
            </label>
          </Grid>
          <Typography variant="h6" className="mb-1">
            {user?.profile_attributes?.first_name}
          </Typography>
        </div>
        <Divider/>
        <div align="left" className="mt-2 mb-1">
          <Typography className="mb-1">
            Email: {user?.email}
          </Typography>
          <Typography>
            Contact No.: {user?.phone}
          </Typography>
        </div>
        <Grid item lg={12} md={12} xs={12} align="right">
          <Tooltip title="Edit profile details">
            <Avatar className="hand-cursor mb-1" onClick={() => handleClickOpen()}>
              <EditIcon/>
            </Avatar>
          </Tooltip>
        </Grid>
        {!user?.profile_attributes?.logo_attributes?.id &&
        <>
          <Divider/>
          <div className="mt-2" align="right">
            <SharedImageUpload img={user?.profile_attributes?.logo_attributes?.large}/>
          </div>
        </>
        }
      </Paper>
      <UserEditProfile handleAction={handleClose} open={open} editUser={user}/>
    </div>
  );
};
