import React, {useReducer} from 'react';
import useCombinedReducers from 'use-combined-reducers';
import {SnackbarProvider} from 'notistack';
import {DispatchContext, initialUser, StateContext, userReducer} from './store';
import './App.css';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import {history} from './utils/history';
import {API} from './utils/api';
import {RoutesUser} from './routes/Routes';
import {UserRoute} from './routes/userRoutes';
import {SignIn} from './containers/auth';
import {SignUp} from './containers/auth/vendor-registration';
import {ProtectedRoute} from './routes/protectedRoute';
import {ConfirmEmail} from './containers/auth/ConfirmEmail';
import {NavList} from './routes/navList';

function App(props) {
  const [state, dispatch] = useCombinedReducers({
    user: useReducer(userReducer, initialUser),
  });

  API.dispatch = dispatch;

  return (
    <React.Fragment>
      <div>
        <DispatchContext.Provider value={dispatch}>
          <StateContext.Provider value={state}>
            <SnackbarProvider maxSnack={2}>
              <Router history={history}>
                <Switch>
                  {
                    NavList.map((nav, index) => (
                      <ProtectedRoute {...nav} key={index} path={nav.path}
                                      component={nav.component} exact/>))
                  }
                  {
                    RoutesUser.map((nav, index) => (
                      <UserRoute {...nav} key={index} path={nav.path}
                                 component={nav.component} exact/>))
                  }
                  <Route path="/login" component={SignIn} exact/>
                  <Route path="/register" component={SignUp} exact/>
                  <Route path={`/auth/confirmation`} component={ConfirmEmail} exact/>
                </Switch>
              </Router>
            </SnackbarProvider>
          </StateContext.Provider>
        </DispatchContext.Provider>
      </div>
    </React.Fragment>
  );
}

export default App;
