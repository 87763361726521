import React, {useEffect, useState} from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import './vendor-carousel.scss';
import {Typography} from '@material-ui/core';
import {userApi, vendorsApi} from '../../services/userServices';
import {Notify, setProps} from '../../shared/notification';
import {useSnackbar} from 'notistack';

const VendorCarouselList = (props) => {
    const snackbar = useSnackbar();
    const [vendors, setVendors] = useState({});
    const [open, setOpen] = useState(false);

    useEffect(() => {
      setProps(snackbar);
      fetchData();
    }, []);

    const fetchData = () => {
      vendorsApi(null, null, 'get').then(response => {
        setVendors([...response.profiles]);
        setOpen(true);
      }).catch(err => {
        setOpen(true);
        Notify(err, 'error');
      });
    };

    const [responsive] = useState({
      responsive: {
        0: {
          nav: false,
          dots: true,
          items: '2'
        },
        767: {
          nav: true
        },
      },
    });

    return (
      <div>
        {open &&
        <div className="banner mb-2" align="center">
          <Typography variant={'h4'} style={{textAlign: 'center'}} className="mt-3">Registered Vendors</Typography>
          <OwlCarousel
            className="banner-owl owl-carousel owl-theme"
            loop={true}
            margin={0}
            autoplay={true}
            autoplayTimeout={6000}
            smartSpeed={600}
            nav={false}
            items="6"
            responsive={responsive.responsive}
            dots={true}>
            {vendors.length > 0 && vendors.map((list, index) => (
              <div className="item">
                <div>
                  <img src={list?.logo_attributes?.original} alt="" className="vendor-img"/>
                  <Typography>{list?.first_name}</Typography>
                </div>
              </div>
            ))
            }
          </OwlCarousel>
        </div>
        }
      </div>
    );
  }
;

export default VendorCarouselList;
