import axios from 'axios';
import {createBrowserHistory} from 'history';
import {logout} from '../services/auth';

const history = createBrowserHistory();
export const apiUrl = 'https://byg.dcpl.bt/api/v1/';
export const DISPATCH = {dispatch: null};
axios.defaults.baseURL = apiUrl;
axios.defaults.timeout = 0;

export const API = {axios: axios.create({baseURL: apiUrl, timeout: 0}), dispatch: null};

// Add a request interceptor
API.axios.interceptors.request.use(function (config) {
  const token = JSON.parse(localStorage.getItem('token'));
  if (token != null) {
    config.headers.Authorization = token;
  }
  return config;
}, function (error) {
  if (!error.response) {
    return Promise.reject(error.message);
  }
  let message = error?.response?.data?.error || error?.response?.data?.errors.join(', ');
  // Do something with request error
  return Promise.reject(message);
});

API.axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  if (!error.response) {
    return Promise.reject(error.message);
  }
  if (401 === error.response.status) {
    sessionStorage.clear();
    localStorage.clear();
    API.dispatch({type: 'LOGOUT'});
    logout(API.dispatch).then(res => {
      history.push(`/login`);
    });
  }
  let message = error?.response?.data?.error || error?.response?.data?.errors.join(', ');
  return Promise.reject(message);
});

export default (url, method, params, data, responseType) => API.axios.request({
  url,
  method,
  params,
  data,
  responseType
});
