import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import './style.scss';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import {Field, Form, Formik} from 'formik';
import * as Yup from 'yup';
import {TextField} from 'formik-material-ui';
import {Notify} from '../../shared/notification';
import {userApi} from '../../services/userServices';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Field is required'),
  phone: Yup.string().required('Field is required'),
  password: Yup.string().required('This field is required').min(6),
  confirmation_password: Yup.string().required('This field is required').when('password', {
    is: val => (val && val.length > 0 ? true : false),
    then: Yup.string().oneOf(
      [Yup.ref('password')],
      'Password Mismatch'
    )
  }),
  profile_attributes: Yup.object().shape({
    first_name: Yup.string().required('Field is required'),
  })
});

export const SignUp = () => {
  const history = useHistory();

  const initialValues = {
    email: '',
    phone: '',
    password: '',
    role_ids: [1],
    confirmation_password: '',
    profile_attributes: {
      first_name: '',
    }
  };

  const onSubmit = (values, {setSubmitting}) => {
    userApi({user: {...values}}, null, 'post').then(response => {
      Notify('Confirmation link send to Email', 'success');
      history.push('/login');
    }).catch(err => {
      setSubmitting(false);
      Notify(err, 'error');
    });
  };

  return (
    <div className="auth-page">
      <Grid container>
        <Grid item lg={12} xs={12} align="center">
          <Paper className="paper">
            <div>
              <Typography className="login-text">
                Vendor Registration
              </Typography>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validationSchema={validationSchema}
              validateOnBlur={true}
            >
              {({errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting, values, ...formik}) => (
                <Form autoComplete="off" noValidate id="invitationForm">
                  <Grid container spacing={2}>
                    <Grid item lg={12} xs={12}>
                      <Field component={TextField} variant="outlined" name="profile_attributes.first_name"
                             label="Name of Vendor"
                             fullWidth/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Field component={TextField} variant="outlined" name="email" label="Email" fullWidth/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Field component={TextField} variant="outlined" name="phone" label="Contact No." fullWidth/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Field component={TextField} variant="outlined" type="password" name="password" label="Password"
                             fullWidth/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Field component={TextField} variant="outlined" type="password" name="confirmation_password"
                             label="Confirm Password" fullWidth/>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Button
                        variant="contained"
                        className="btn-register"
                        type="submit"
                        disabled={isSubmitting}
                      >Register</Button>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className="mt-1 hand-cursor link-color" onClick={() => history.push('/login')}>Back to
                        Login</Typography>
                    </Grid>
                    <Grid item lg={12} xs={12}>
                      <Typography className="mt-2 gray-color">Copyright @ Dragon Coders Pvt. Ltd</Typography>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
};