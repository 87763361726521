import React, {useEffect, useState} from 'react';
import '../list-sport-facility.scss';
import {Card, Grid} from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import {Notify, setProps} from '../../../shared/notification';
import {facilityAPi} from '../../../services/verdor.service';
import {Pagination} from '@material-ui/lab';
import {truncate} from '../../../shared/truncate';

export const ViewAllSportFacility = (props) => {
  const history = useHistory();
  const [facilities, setFacilities] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(1);
  const [open, setOpen] = useState(false);

  const snackbar = useSnackbar();
  setProps(snackbar);
  useEffect(() => {
    setProps(props);
    fetchServices();
  }, []);

  const fetchServices = () => {
    facilityAPi('get', {
      page: page,
      per_page: 9,
    }).then(response => {
      setTotal(response.meta.last_page);
      setFacilities(response.facilities);
      setOpen(true);
    }).catch(err => {
      setOpen(true);
      Notify(err, 'error');
    });
  };

  const handleChangePage = (event, page) => {
    setPage(page);
  };

  const viewDetail = (id) => {
    history.push(`/facility/${id}`);
  };

  return (
    <div>
      {open &&
      <div className="facility-section mt-5">
        <h2 className="facilities mt-5">Sports Facilities</h2>
        <Grid container spacing={2}>
          {facilities.length > 0 ? facilities.map((list, index) => (
              <Grid item lg={3} xs={12}>
                <Card>
                  <div>
                    <img src={list?.photos_attributes?.[0]?.medium} alt=""
                         className="card-image"/>
                  </div>
                  <CardContent
                    style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingBottom: 18}}>
                    <Typography variant="h6" className="vendor">
                      {truncate(list?.name)}
                    </Typography>
                    <Button variant="contained" className="view-button text-capitalize"
                            onClick={() => viewDetail(list?.id)}>View
                      Detail</Button>
                  </CardContent>
                </Card>
              </Grid>
            )) :
            (
              <Typography variant={'h6'} align={'center'} component="div">
                No data to display
              </Typography>
            )
          }
        </Grid>
        <div className="mb-4 mt-4">
          <Pagination
            count={total}
            page={page}
            className="pull-right"
            onChange={handleChangePage}
            variant="outlined"
            style={{background: 'white', borderRadius: 25}}/>
        </div>
      </div>
      }
    </div>
  );
};
