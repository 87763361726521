import baseApi from '../utils/api';

export const userApi = (payload, params, method, dispatch) => {
  return baseApi(`/users`, method, params, payload, dispatch).then(response => {
    return response.data;
  });
};

export const vendorsApi = (payload, params, method, dispatch) => {
  return baseApi(`/users/profile`, method, params, payload, dispatch).then(response => {
    return response.data;
  });
};

export const userAction = (id, method, payload, params) => {
  return baseApi(`/users/${id}`, method, params, payload).then(response => {
    return response.data;
  });
};

export const reservationApi = (id, payload, params, method, dispatch) => {
  return baseApi(`/facilities/${id}/reservations`, method, params, payload, dispatch).then(response => {
    return response.data;
  });
};

