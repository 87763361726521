import React from 'react';
import {Card, CardContent, Container, Divider, Grid, TextField, Typography} from '@material-ui/core';
import './contact-us.scss';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Button from '@material-ui/core/Button';

export default function ContactUs() {
  return (
    <div id="contact" className="contact">
      <div className="pt-5 pb-5">
        <h3 style={{color: 'white', textAlign: 'center', marginTop: 10}}>CONTACT US</h3>
      </div>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <Card className="address-card">
              <CardContent>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <LocationOnIcon style={{color: 'white'}}/>
                  <h3 className="text ml-1 mt-0">Address</h3>
                </div>
                <Typography className="text mt-4">Chubachu, Thimphu Bhutan</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Card className="address-card">
              <CardContent>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <MailIcon style={{color: 'white'}}/>
                  <h3 className="text ml-1 mt-0">Mail</h3>
                </div>
                <Typography className="text mt-4">info@dcpl.bt</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item lg={4} xs={12}>
            <Card className="address-card">
              <CardContent>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                  <PhoneIcon style={{color: 'white'}}/>
                  <h3 className="text ml-1 mt-0">Contact Number</h3>
                </div>
                <Typography className="text mt-4">17971633 / 77652334</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item lg={4} xs={12}>
            <Typography variant={'h5'} className="text-white mb-2 mt-2">Get in Touch</Typography>
            <Divider style={{backgroundColor: 'gray'}}/>
            <Typography className="text-white mt-2 mb-4">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Adipisci autem consequuntur corporis culpa dicta
              doloribus ea et, id illo ipsum labore laborum
            </Typography>
            <FacebookIcon className="facebook"/>
            <InstagramIcon className="instagram"/>
            <TwitterIcon className="twitter"/>
            <LinkedInIcon className="linked-in"/>
          </Grid>
          <Grid item lg={8} xs={12} align="center">
            <Grid container spacing={1}>
              <Grid item lg={6} xs={12}>
                <TextField variant="outlined" placeholder="Name" className="contact-form" margin="dense" fullWidth/>
              </Grid>
              <Grid item lg={6} xs={12}>
                <TextField variant="outlined" placeholder="Email" className="contact-form" margin="dense" fullWidth/>
              </Grid>
              <Grid item lg={12} xs={12}>
                <TextareaAutosize aria-label="minimum height" rowsMin={3} placeholder="Message here"
                                  style={{width: '100%', height: 100, padding: 10}}/>
              </Grid>
              <Grid item lg={12} xs={12} align="left">
                <Button variant="contained" className="message-button">Message</Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}