import React, {useState} from 'react';
import Banner from '../banner/banner';
import {SportFacility} from '../sport-facility/list-sport-facility';
import About from '../about-us/about-us';
import {WhyByg} from '../why-byg/why-byg';
import ContactUs from '../contact-us/contact-us';
import VendorCarouselList from '../vendors/vendor-carousel';

export const LandingComponent = () => {
  const [querySearch, setQuerySearch] = useState('');
  const handleSearch = (inputSearch) => {
    setQuerySearch(inputSearch);
  };

  return (
    <React.Fragment>
      <Banner handleSearch={handleSearch}/>
      <SportFacility querySearch={querySearch}/>
      <About/>
      <VendorCarouselList/>
      <WhyByg/>
      <ContactUs/>
    </React.Fragment>
  );
};