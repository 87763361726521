import React from 'react';
import './why-byg.scss';
import OnlineIcon from '@material-ui/icons/FlashOn';
import OperationIcon from '@material-ui/icons/Build';
import MarketIcon from '@material-ui/icons/VpnLock';

export const WhyByg = () => {
  return (
    <div>
      <div className="parallax p-5">
        <h2 className="text-center" style={{fontSize: 30, fontWeight: 'bold', color: 'white'}}>
          BYG FOR SPORTS VENUE OPERATORS
        </h2>
        <p className="text-center mt-2" style={{fontSize: 20, color: 'white'}}>
          Go digital with BYG portal and let your users reap the benefits of fast, smooth and efficient
          booking of your sports venues!
        </p>
        <div className="venue-partner parallax pt-2" style={{color: 'white'}}>
          <div className="venue">
            <h2 className="text-center">
              <OnlineIcon style={{width: '50px', height: '50px'}}/>
            </h2>
            <h2 className="block text-center mb-4">Online Bookings</h2>
            <span className="text-center">
                We enable venues to take online bookings, resulting in increased booking conversion rates and revenue
              </span>
          </div>
          <div className="venue" style={{color: 'white'}}>
            <h2 className="text-center">
              <MarketIcon style={{width: '50px', height: '50px'}}/>
            </h2>
            <h2 className="block text-center mb-4">Marketing Exposure</h2>
            <span className="text-center">
                Benefit from BYG marketing campaigns giving your venue increased exposure through social media
              </span>
          </div>
          <div className="venue" style={{color: 'white'}}>
            <h2 className="text-center">
              <OperationIcon style={{width: '50px', height: '50px'}}/>
            </h2>
            <h2 className="block text-center mb-4">Support</h2>
            <span className="text-center">We render technical and operational supports</span>
          </div>
        </div>
      </div>
    </div>
  );
};