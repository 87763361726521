import {IS_LOADING, NOT_LOADING} from './action.types';

export const loaderReducer = (state, action) => {
  switch (action.type) {
    case IS_LOADING:
      return IS_LOADING;
    case NOT_LOADING:
      return NOT_LOADING;
    default:
      return state;
  }
};

export const loaderStatusReducer = (state, action) => {
  return action.type;
};