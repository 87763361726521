import {LandingComponent} from '../views/ladning-component/landing-component';
import {SportFacilityDetail} from '../views/sport-facility/detail-sport-facility/detail-sport-facility';
import {ViewAllSportFacility} from '../views/sport-facility/view-all-sport-facility/view-all-sport-facility';

export const RoutesUser = [
  {
    primaryText: 'Home',
    path: '/',
    component: LandingComponent
  },
  {
    primaryText: 'facility detail',
    icon: 'detail',
    path: '/facility/:id',
    component: SportFacilityDetail
  },
  {
    primaryText: 'facility view all',
    icon: 'facility',
    path: '/view-all-facility',
    component: ViewAllSportFacility
  },
];
