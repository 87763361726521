import React from 'react';
import './about-us.scss';
import Grid from '@material-ui/core/Grid';
import {Container, Typography} from '@material-ui/core';

export default function About() {
  return (
    <div className="card-page" id="about">
      <h2 className="about-text mt-0">About Us</h2>
      <Container>
        <Grid container spacing={2}>
          <Grid item lg={5} xs={12}>
            <img src={require('../../assets/images/football.jpeg').default} alt="" className="football-image"/>
          </Grid>
          <Grid item lg={7} xs={12}>
            <Typography style={{textAlign: 'left'}}>
              <span className="title-bold"> BYG (Book Your Ground)</span> is an online platform for sports ground
              booking through the convenience of your laptop, mobile or other devices, developed in-house by
              Dragon Coders Pvt. Ltd., a Bhutanese software firm located in the heart of Thimphu town.
            </Typography>
            <h3 className="green-color" style={{textAlign: 'left'}}>Users</h3>
            <Typography style={{textAlign: 'left'}}>
              As sports enthusiasts, <span className="title-bold">DCPL</span> understands the pain of booking
              sports ground, being double booked and the amount of time wasted in calling each sports ground owners
              and trying to find an available slot.
            </Typography>
            <h3 className="green-color" style={{textAlign: 'left'}}>Ground Owners</h3>
            <Typography style={{textAlign: 'left'}}>
              For sports ground owners, BYG will bring you added assurance of reduced no-show or last minute
              cancellations. You also have the ability to create recurring bookings for your regular or corporate
              customers. Most importantly, you will be able to have a clear view of the revenue generated on a daily
              basis.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
