import React, {useContext, useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TablePagination from '@material-ui/core/TablePagination';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import {facilityAction, facilityAPi} from '../../../services/verdor.service';
import {Notify, setProps} from '../../../shared/notification';
import {useSnackbar} from 'notistack';
import Typography from '@material-ui/core/Typography';
import ConfirmDialog from '../../../shared/confirmDialog';
import {truncate} from '../../../shared/truncate';
import {StateContext} from '../../../store';

export const ListFacility = (props) => {
  const snackbar = useSnackbar();
  const history = useHistory();
  const [facilities, setFacilities] = useState({});
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [open, setOpen] = useState(false);
  const [id, setId] = useState('');
  const {user} = useContext(StateContext);
  const ids = user?.id;

  useEffect(() => {
    setProps(snackbar);
    fetchServices();
  }, []);

  const fetchServices = () => {
    facilityAPi('get', {
      page: page,
      per_page: 8,
      user_id: ids,
    }).then(response => {
      setTotal(response.meta.last_page);
      setFacilities(response.facilities);
    }).catch(err => {
      Notify(err, 'error');
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = async (result) => {
    setOpen(false);
    if (result) {
      await facilityAction(id, 'delete').then(res => {
        Notify('Facility deleted successfully', 'success');
        fetchServices();
      }).catch(err => {
        Notify(err, 'error');
      });
    }
  };

  const handleOpen = (id) => {
    setId(id);
    setOpen(true);
  };

  const viewDetail = (id) => {
    history.push(`/facility/${id}/reservation`);
  };

  const editService = (id) => {
    history.push(`/add-facility/${id}?edit=${true}`);
  };

  const addFacility = () => {
    history.push('/add-facility');
  };

  return (
    <div>
      <section>
        <Button className="mb-1" variant="contained" color={'primary'}
                style={{float: 'right', textTransform: 'capitalize'}} onClick={addFacility}>Create Facility</Button>
      </section>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow hover style={{backgroundColor: '#dfe5f0'}}>
              <TableCell>Id</TableCell>
              <TableCell>Facility Type</TableCell>
              <TableCell>Facility Name</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Description</TableCell>
              <TableCell colSpan={3}/>
            </TableRow>
          </TableHead>
          <TableBody>
            {facilities.length > 0 ? facilities.map((list, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{list?.facility_category?.name}</TableCell>
                  <TableCell>{list?.name}</TableCell>
                  <TableCell>{list?.location}, {list?.dzongkhag} </TableCell>
                  <TableCell>{truncate(list?.description)}</TableCell>
                  <TableCell>
                    <Tooltip title="edit">
                      <EditIcon color="primary" className="hand-cursor" onClick={() => editService(list.id)}/>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title="delete">
                      <DeleteIcon className="hand-cursor" color="secondary" onClick={() => handleOpen(list?.id)}/>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={'detail'}>
                      <ArrowForwardIcon className="hand-cursor" style={{color: 'green'}}
                                        onClick={() => viewDetail(list?.id)}/>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              )) :
              (<TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  <Typography variant={'h6'} align={'center'} component="div">
                    No data to display
                  </Typography>
                </TableCell>
              </TableRow>)
            }
          </TableBody>
        </Table>
      </TableContainer>
      <ConfirmDialog open={open} handleAction={handleDelete}/>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        rowsPerPage={rowsPerPage}
        count={total}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};