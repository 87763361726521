import React, {useContext, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {Notify, setProps} from '../../shared/notification';
import {useSnackbar} from 'notistack';
import {emailConfirmation} from '../../services/auth';
import {DispatchContext} from '../../store';


export const ConfirmEmail = (props) => {
  const search = props.location.search;
  const params = new URLSearchParams(search);
  const authority = params.get('confirmation_token');
  const snackbar = useSnackbar();
  const history = useHistory();
  const dispatch = useContext(DispatchContext);

  useEffect(() => {
    setProps(snackbar);
    emailConfirmation({confirmation_token: authority}, dispatch).then(response => {
      history.push('/profile');
      Notify('Registered Successfully', 'success');
    }).catch(err => {
      Notify(err, 'error');
    });
  }, []);
  return (
    <React.Fragment/>
  );
};


