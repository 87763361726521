import React, {useEffect, useState} from 'react';
import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@material-ui/core';
import TablePagination from '@material-ui/core/TablePagination';
import Typography from '@material-ui/core/Typography';
import {Notify, setProps} from '../../../../shared/notification';
import {reservationApi} from '../../../../services/userServices';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import Moment from 'react-moment';


export const ListReservation = (props) => {
  const snackbar = useSnackbar();
  const [total, setTotal] = useState(0);
  const [page, setPage] = React.useState(1);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [customer, setCustomer] = useState({});
  const params = useParams();
  const id = params?.id;

  useEffect(() => {
    setProps(snackbar);
    fetchServices();
  }, []);

  const fetchServices = () => {
    reservationApi(id, null, {
      page: page,
    }, 'get').then(response => {
      setTotal(response.meta.last_page);
      setCustomer(response.reservations);
    }).catch(err => {
      Notify(err, 'error');
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <div>
      <section>
        <Typography variant="h5" style={{textAlign: 'center', marginTop: 20, marginBottom: 20}}>Reservation
          List</Typography>
      </section>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow hover style={{backgroundColor: '#dfe5f0'}}>
              <TableCell>Id</TableCell>
              <TableCell>Customer Name</TableCell>
              <TableCell>Phone No.</TableCell>
              <TableCell>Date</TableCell>
              <TableCell>Time</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customer.length > 0 ? customer.map((list, index) => (
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>{list?.customer_name}</TableCell>
                  <TableCell>{list?.phone}</TableCell>
                  <TableCell><Moment format="LL" withTitle>{list?.date}</Moment></TableCell>
                  <TableCell>{list?.from} - {list?.to}</TableCell>
                </TableRow>
              )) :
              (<TableRow>
                <TableCell component="th" scope="row" colSpan={6}>
                  <Typography variant={'h6'} align={'center'} component="div">
                    No Customer to display
                  </Typography>
                </TableCell>
              </TableRow>)
            }
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        rowsPerPage={rowsPerPage}
        count={total}
        page={page - 1}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </div>
  );
};