import React, {useEffect, useState} from 'react';
import {Button, Grid} from '@material-ui/core';
import './detail-sport-facility.scss';
import Typography from '@material-ui/core/Typography';
import {Notify, setProps} from '../../../shared/notification';
import {facilityAction} from '../../../services/verdor.service';
import {useSnackbar} from 'notistack';
import {useParams} from 'react-router-dom';
import FacilityReservation from './reservation';

export const SportFacilityDetail = (props) => {
    const snackbar = useSnackbar();
    const [open, setOpen] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const params = useParams();
    const id = params?.id;
    const [facility, setFacility] = useState({});
    const [toggled, setToggled] = React.useState(true);
    const [images, setImages] = React.useState({});


    useEffect(() => {
      setProps(snackbar);
      fetchProduct();
    }, []);

    const fetchProduct = () => {
      facilityAction(id, 'get').then(response => {
        setFacility(response.facility);
        setImages(response.facility?.photos_attributes?.[0])
        setShow(true);
      }).catch(err => {
        setShow(true);
        Notify(err, 'error');
      });
    };

    const handleOpen = () => {
      setOpen(true);
    };

    const handleClose = (result) => {
      setOpen(result);
    };

    const toggleImage = (image) => {
      setImages(image);
      setToggled(!toggled);
    };

    return (
      <div>
        {show &&
        <div className="detail-facility-section">
          <h2 className="detail-facilities-text">Sports Facilities Detail</h2>
          <div className="detail-container">
            <Grid container spacing={2}>
              <Grid item lg={6} xs={12}>
                <Grid container spacing={1}>
                  <Grid item lg={12} xs={12}>
                        <div>
                          <img src={images?.original} alt=""
                               className="detail-card-image"/>
                        </div>
                  </Grid>
                  <Grid item lg={12} xs={12} align="center">
                    {facility?.photos_attributes?.length > 0 && facility?.photos_attributes?.map((image, index) => (
                      <img src={image?.small} alt="" key={index}
                           className="thumbnail hand-cursor"
                           onClick={() => toggleImage(image)}/>
                    ))}
                    <div>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item lg={6} xs={12}>
                <div>
                  <h2 style={{color: 'green', textAlign: 'left'}}>{facility?.name}</h2>
                  <h3 style={{textAlign: 'left'}}>Location: {facility?.location}, {facility?.dzongkhag}</h3>
                  <Typography style={{textAlign: 'left'}}>
                    {facility?.description}
                  </Typography>
                </div>
                <Button variant="contained" style={{float: 'left'}} className="booking-button"
                        onClick={handleOpen}>Send Booking Request</Button>
              </Grid>
            </Grid>
          </div>
          <div>
          </div>
        </div>
        }
        {open && <FacilityReservation id={id} open={open} handleAction={handleClose}/>}
      </div>
    );
  }
;
