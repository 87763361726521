import baseApi from '../utils/api';

export const facilityAPi = (method, params, payload = null) => {
  return baseApi(`/facilities`, method, params, payload).then(response => {
    return response.data;
  })
};

export const facilityAction = (id, method,params, payload = null ) => {
  return baseApi(`/facilities/${id}`, method, params, payload).then(response => {
    return response.data;
  })
};

export const categoryList = (params) => {
  return baseApi(`/facility_categories`, 'get', params).then(response => {
    return response.data;
  })
};