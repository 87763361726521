import React, {useContext} from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Icon from '@material-ui/core/Icon';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {NavLink, useHistory, useLocation} from 'react-router-dom';
import './sidebar.scss';
import Typography from '@material-ui/core/Typography';
import SportsSoccerIcon from '@material-ui/icons/SportsSoccer';
import {DispatchContext, IS_LOADING, NOT_LOADING, StateContext} from '../../store';
import {logout} from '../../services/auth';

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },

  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
    boxShadow: 'none',
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const navList = [
  {
    primaryText: 'My Profile',
    icon: <AccountCircleIcon/>,
    path: '/profile'
  },
  {
    primaryText: 'My Facility',
    icon: <SportsSoccerIcon/>,
    path: '/list-facility'
  },
];

export const Sidebar = (props) => {
  const {container} = props;
  const classes = useStyles();
  const theme = useTheme();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const {user} = useContext(StateContext);
  const dispatch = useContext(DispatchContext);
  const history = useHistory();
  const id = user?.id;

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const location = useLocation();
  const isActiveRoute = (path) => {
    return location.pathname === path;
  };

  const signOut = async () => {
    dispatch({type: IS_LOADING});
    await logout(dispatch);
    dispatch({type: NOT_LOADING});
    history.push('/login');
  };

  const drawer = (
    <div>
      <div className={classes.toolbar} align="center">
        <IconButton
          aria-label="admin user"
          aria-controls="menu-appbar"
          aria-haspopup="true"
          color="inherit"
        >
          <img
            src={(user?.profile_attributes?.profile_photo_attributes?.original) || require('../../assets/images/background-banner.png').default}
            className="side-profile" alt=""/>
        </IconButton>
        <Typography className="mb-1">
          {user?.profile_attributes?.first_name}
        </Typography>
      </div>
      <Divider style={{marginBottom: 10}}/>
      {navList.map(({primaryText, icon, path}, i) => (
        <ListItem key={primaryText} selected={isActiveRoute(path)} button component={NavLink} to={path}>
          <ListItemIcon>
            <Icon>{icon}</Icon>
          </ListItemIcon>
          <ListItemText
            primary={primaryText}
            primaryTypographyProps={{noWrap: true}}
          />
        </ListItem>
      ))}
      <Divider style={{margin: '10px 0'}}/>
      <List>
        <ListItem button>
          <ListItemIcon>
            <ExitToAppIcon/>
          </ListItemIcon>
          <ListItemText
            primary={'Logout'}
            primaryTypographyProps={{noWrap: true}}
            onClick={signOut}
          />
        </ListItem>
      </List>
    </div>
  );

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon/>
          </IconButton>
          <div/>
          <NavLink to={'/'}>
            <img src={require('../../assets/images/byg-logo.jpg').default} className="logo"/>
          </NavLink>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            container={container}
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content} style={{marginTop: 50}}>
        {props.children}
      </main>
    </div>
  );
};