import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import SearchIcon from '@material-ui/icons/Search';
import {Grid, Hidden, Typography} from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Search from '../../shared/search/search';
import './banner.scss';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
  },
}));

export default function Banner({handleSearch}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline/>
      <main className={classes.content}>
        <div className="top-mix-banner"/>
        <div>
          <img src={require('../../assets/images/background-banner.png').default}
               className="image-size embed-responsive" alt="First slide"
          />
        </div>
        <div>
          <div className="main-text">
            <h1 className="intro-title wow fadeInUp" data-wow-delay="0.8s">
              <Typography className="text-ground">FIND <span> YOUR </span> GROUND</Typography>
            </h1>
          </div>
        </div>
        <div className="banner-bottom">
          <Grid container>
            <Grid item lg={1} xs={1} align="center">
              <SearchIcon className="icons" onChange={handleSearch}/>
            </Grid>
            <Hidden smUp>
              <Grid item lg={2} xs={2} align="left">
                <Typography className="bottom-text">FIND</Typography>
              </Grid>
            </Hidden>
            <Hidden smDown>
              <Grid item lg={4} xs={2} align="center">
                <div style={{marginTop: 12}}>
                  <Search placeholder="Search Facility" onChange={handleSearch}/>
                </div>
              </Grid>
            </Hidden>
            <Grid item lg={1} xs={1} align="center">
              <ArrowForwardIosIcon className="icons"/>
            </Grid>
            <Grid item lg={1} xs={2}>
              <Typography className="bottom-text">BOOK</Typography>
            </Grid>
            <Grid item lg={1} xs={1} align="center">
              <ArrowForwardIosIcon className="icons"/>
            </Grid>
            <Grid item lg={1} xs={2}>
              <Typography className="bottom-text">PLAY</Typography>
            </Grid>
          </Grid>
        </div>
      </main>
    </div>
  );
}