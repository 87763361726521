import React from 'react';
import {Header} from '../header/header';
import Footer from '../footer/footer';

function HomeComponent({children}) {
  return (
    <div id="home">
      <Header/>
      <main>
        {children}
      </main>
      <Footer/>
    </div>
  );
}

export default HomeComponent;
