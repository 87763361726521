import React from 'react';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import {Link} from '@material-ui/core';
import './footer.scss';

function Footer() {
  return (
    <div className="footer">
      <Divider/>
      <Typography variant="h6" style={{paddingTop: 10, fontSize: 16, textAlign: 'center'}}
                  className='mt-3'>
        {'Copyright @'}
        {' '}
        <Link color="inherit" href="https://dcpl.bt/" style={{textDecoration: 'none', color: 'white'}} target="_blank">
          Dragon Coders Pvt. Ltd.
        </Link>
        {' '}
        {new Date().getFullYear()}
        {'.'} All rights Reserved
      </Typography>
    </div>
  );
}

export default Footer;
