import {Profile} from '../components/profile/profile';
import {ListFacility} from '../components/facility/list-facility/list-facility';
import {AddFacility} from '../components/facility/add-facility/add-facility';
import {ListReservation} from '../components/reservation/admin/list-reservation/list-reservation';

export const NavList = [
  {
    primaryText: 'profile',
    icon: 'profile',
    path: '/profile',
    authorized: ['Admin'],
    component: Profile
  },
  {
    primaryText: 'List Reservation',
    icon: 'reservation',
    path: '/facility/:id/reservation',
    authorized: ['Admin'],
    component: ListReservation
  },
  {
    primaryText: 'List Facility',
    icon: 'facility',
    path: '/list-facility',
    authorized: ['Admin'],
    component: ListFacility
  },
  {
    primaryText: 'Add Facility',
    icon: 'facility',
    path: '/add-facility',
    authorized: ['Admin'],
    component: AddFacility
  },
  {
    primaryText: 'Edit Facility',
    icon: 'createService',
    path: '/add-facility/:id',
    authorized: ['Admin'],
    component: AddFacility
  },
];