import React, {useContext} from 'react';
import {Card, Dialog, DialogActions, DialogContent, Grid} from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import './profileEdit.scss';
import {Field, Form, Formik} from 'formik';
import {TextField} from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import {useHistory} from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import {userApi} from '../../services/userServices';
import {useSnackbar} from 'notistack';
import {Notify, setProps} from '../../shared/notification';
import {SharedImageUpload} from '../../shared/image-upload/image-upload';
import {DispatchContext} from '../../store';

export default function UserEditProfile(props) {
  const dispatch = useContext(DispatchContext);
  const {handleClose, open, editUser, handleAction} = props;
  const snackbar = useSnackbar();
  setProps(snackbar);

  const userEdit = (values, setSubmitting) => {
    userApi({user: {...values}}, null, 'put').then(response => {
      localStorage.setItem('user', JSON.stringify(response['user']));
      dispatch({type: 'LOGIN', payload: response.user});
      Notify('User Updated Successfully', 'success');
      handleAction(false, response?.user);
    }).catch(err => {
      Notify(err, 'error');
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="draggable-dialog-title"
      >
        <Card className="p-4 edit-container">
          <Grid container spacing={2}>
            <Grid item lg={6} md={6} xs={6}>
              <Typography variant={'h6'} className="mb-3">
                <b>Edit profile</b>
              </Typography>
            </Grid>
            <Grid item lg={6} md={6} xs={6} align="right">
              <CloseIcon onClick={(e) => handleAction(false, e)}/>
            </Grid>
          </Grid>
          <DialogContent dividers>
            <Formik
              onSubmit={userEdit}
              initialValues={editUser}
              validateOnBlur={true}
            >
              {({isSubmitting, touched, errors, values, ...formik}) => (
                <Form autoComplete="off" noValidate>
                  <Grid container spacing={2}>
                    <Grid item lg={12}>
                      <Field component={TextField} size="small" name="profile_attributes.first_name" variant="outlined"
                             label="First Name" fullWidth/>
                    </Grid>
                    <Grid item lg={12}>
                      <Field component={TextField} size="small" name="phone" variant="outlined"
                             label="Phone" fullWidth/>
                    </Grid>
                    <Grid item lg={12}>
                      <Field component={TextField} size="small" name="email" variant="outlined" label="Email"
                             fullWidth/>
                    </Grid>
                  </Grid>
                  <Grid item lg={12}>
                    <div className="mt-2" align="right">
                      <SharedImageUpload img={editUser?.profile_attributes?.logo_attributes?.large}/>
                    </div>
                  </Grid>
                  <DialogActions className="pr-0">
                    <Button variant="contained" type="submit" className="user-edit-profile">
                      Update
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          </DialogContent>
        </Card>
      </Dialog>
    </div>
  );
}
